import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const aboutPage: React.FC<{}> = () => (
	<>
		<SEO title="about us" />
		<PrimaryLayout>
		<Container py="40px">
			<h1>About Us</h1>
				<p>
					Coming soon!
				</p>		
		</Container>
		</PrimaryLayout>		
	</>
);

export default aboutPage;
